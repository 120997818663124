/*--
/*  4.5 - Newsletter CSS
/*----------------------------------------*/

// Newsletter Wrapper
.newsletter-wrapper{
    background-size: 100% 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$tablet-device}{
        flex-direction: column;
    }
    @media #{$large-mobile}{
        height: auto;
        padding: 30px 10px;
        display: block;
        background-size: cover;
        background-position: center center;
    }
}

// Newsletter Title
.newsletter-title{
    padding: 0 15px;
    & .title{
        font-weight: 400;
        color: $dark;
        font-size: 38px;
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
}

// Newsletter Form
.newsletter-form{
    max-width: 530px;
    width: 100%;
    position: relative;
    padding: 0 15px;

    & input{
        width: 100%;
        height: 50px;
        border: 0;
        border-bottom: 1px solid #999;
        background: none;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #5a5a5a;
        padding-right: 70px;

        @media #{$small-mobile}{
            padding-right: 62px;
        }

        @include placeholder{
            opacity: 1;
        }

        &:focus{
            outline: none;
        }
    }
    & button{
        position: absolute;
        height: 50px;
        top: 0;
        right: 15px;
        padding: 0;
        border: 0;
        background: none;
        font-family: $headings-font-family;
        font-size: 16px;

        @media #{$small-mobile}{
            font-size: 14px;
        }

        & span{
            position: relative;
            bottom: -2px;
        }
    }
}


