/*--
/*  3.3 - Header Mobile CSS
/*----------------------------------------*/

// Header Mobile 
.header-mobile{
    padding: 15px 0;
}

// Off Canvas Menu
.offcanvas-menu{
    position: fixed;
    top: 0;
    left: -330px;
    width: 320px;
    height: 100%;
    background-color: $white;
    z-index: 1100;
    transition: all 0.3s linear;
    
    &.open{
        left: 0;
    }
}

// Off Canvas Menu Wrapper
.menu-close{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 15px;

    & span{
        width: 20px;
        height: 2px;
        background-color: $dark;
        transform: rotate(-45deg);
        display: block;
        position: relative;

        &:nth-of-type(1){
            transform: rotate(-45deg);
            top: 8px;
        }
        &:nth-of-type(2){
            transform: rotate(45deg);
            top: 6px;
        }
    }
}

// Off Canvas Menu Wrapper
.offcanvas-menu-wrapper{
    padding: 50px 0 30px;
}

// Mobile Primary Menu
.mobile-primary-menu{
    padding-top: 30px;

    & ul{
        & > li{
            position: relative;

            & > a{
                font-family: $headings-font-family;
                font-size: 14px;
                font-weight: 500;
                color: $dark;
                padding: 8px 25px;
                display: block;
                transition: all 0.3s linear;
                border-bottom: 1px solid #f0d4d4;

                @media #{$desktop-device}{
                    font-size: 16px;
                }
            }

            &:hover{
                & > a{
                    color: $primary;
                }                
            }

            & .mobile-menu-expand {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 10px;
                right: 23px;
                cursor: pointer;

                &::before{
                    position: absolute;
                    content: '';
                    width: 13px;
                    height: 1px;
                    background-color: $dark;
                    top: 7px;
                    left: 1px;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 13px;
                    background-color: $dark;
                    left: 7px;
                    bottom: 2px;
                    transition: all 0.1s linear;
                }
            }
            &.active-expand{
                & > .mobile-menu-expand {
                    &::after{
                        height: 0;
                    }
                }
            }

            & ul{
                & li{
                    & a{
                        padding-left: 11%;
                    }
                    & ul{
                        & li{
                            & a{
                                padding-left: 16%;
                            }
                            & ul{
                                & li{
                                    & a{
                                        padding-left: 22%;
                                    }
                                    & ul{
                                        & li{
                                            & a{
                                                padding-left: 27%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .sub-menu,
    & .mega-sub-menu{
        display: none;
    }
}

// Overlay
.menu-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1009;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;

    &.open{
        opacity: 0.4;
        visibility: visible;
    }
}
