/*--
/*  4.13 - Checkout CSS
/*----------------------------------------*/


// Checkout Info 
.checkout-info{
    margin-top: 30px;

    & .info-header{
        padding: 14px 30px;
        margin: 0;
        position: relative;
        background-color: #eeeff2;
        list-style: none outside !important;
        width: auto;
        word-wrap: break-word;
        color: $black;
        font-size: 14px;        
        
        & strong{
            font-weight: 700;
            color: $black;
        }
        & i{
            font-size: 12px;
            color: #6dde6d;
            margin-right: 10px;
        }
        & a{
            color: $black;
            transition: all 0.3s linear;

            &:hover{
                color: $primary;
            }
        }

        &.error{
            & i{
                color: #c43002;
            }
        }
    }
    & .card-body{
        padding: 0;
        padding-top: 20px;

        & p{
            font-size: 14px;
            color: $body-color;
            margin-bottom: 0;
        }

        & .single-form{
            margin-top: 15px;

            & .form-check{
               margin-bottom: 0;
               min-height: inherit;
            }
        }

        & .forget{
            margin-top: 15px;

            & a{
                font-weight: 400;
                color: $body-color;
                transition: all 0.3s linear;
                text-decoration: none;
                font-size: 15px;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

// Checkout Title 
.checkout-title{
    & .title{
        font-size: 24px;
        color: $dark;
        position: relative;
        text-transform: capitalize;

        @media #{$small-mobile}{
            font-size: 18px;
        }
        
        &::after{           
            content: "";
            width: 50px;
            display: block;
            margin-top: 10px;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-color: inherit;            
        }
    }
}

// Checkout Form 
.checkout-form{
    margin-top: 48px;
}

// checkout Account
.checkout-account{
    display: none;
}

// checkout Shipping
.checkout-shipping{
    display: none;
}

// checkout Note
.checkout-note{
    & textarea{
        border: 0;
        background-color: #f7f7f7;
        height: 150px;
        padding-top: 10px;
    }
}

// Checkout Order 
.checkout-order{
    border: 2px solid #ebebeb;
    padding: 40px;
    margin-top: 50px;

    @media #{$desktop-device}{
        padding: 30px;
    }
    @media #{$large-mobile}{
        padding: 40px;
    }

    @media #{$small-mobile}{
        padding: 20px;
    }

    & ul{
        padding: 0;
        margin: 0;
        list-style: none;
    }

    & .table{
        margin-bottom: 0;

        & thead{
            & tr{
                & th{
                    padding: 10px 0;
                    border-top: 0;
                    border-bottom: 1px solid #e1e1e1;
                    font-weight: 400;
                    font-size: 15px;
                    color: $black;
                    vertical-align: middle;

                    @media #{$desktop-device}{
                        font-size: 14px;
                    }
                    @media #{$small-mobile}{
                        font-size: 14px;
                    }

                    &.Product-name{}     
                    &.Product-price{
                        text-align: right;
                    }
                }
            }
        }

        & tbody{
            & tr{
                & td{
                    padding: 5px 0;
                    border-top: 0;
                    vertical-align: middle;

                    & p{
                        font-weight: 400;
                        font-size: 15px;
                        color: $black;

                        @media #{$desktop-device}{
                            font-size: 14px;
                        }
                        @media #{$small-mobile}{
                            font-size: 14px;
                        }
                    }

                    &.Product-name{}     
                    &.Product-price{
                        text-align: right;
                    }
                }
                &:first-child{
                    & td{
                        padding-top: 20px;
                    }
                }
                &:last-child{
                    & td{
                        padding-bottom: 20px;
                    }
                }
            }
        }

        & tfoot{
            & tr{
                & td{
                    padding: 10px 0;
                    vertical-align: middle;

                    & p{
                        font-weight: 400;
                        font-size: 15px;
                        color: $black;

                        @media #{$desktop-device}{
                            font-size: 14px;
                        }
                        @media #{$small-mobile}{
                            font-size: 14px;
                        }
                    }

                    &.Product-name{}     
                    &.Product-price{
                        text-align: right;
                    }
                    &.total-price{                        
                        text-align: right;

                        & p{
                            font-size: 18px;
                        }
                    }
                    & .radio{                        
                        & label{
                            white-space: nowrap;
                            font-size: 15px;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

// Checkout payment 
.checkout-payment{
    margin-top: 30px;

    & ul{
        & li{
            margin-bottom: 3px;
            :last-child{
                margin-bottom: 0;
            }
        }
    }

    & .single-payment{
        & .payment-radio{
            position: relative;
           
            & label{
                font-size: 15px;
                padding-left: 22px;
                
                & .payment{
                    width: 100px;
                    padding: 0 2px;
                }
                & a{
                    color: $black;
                    text-decoration: underline;
                    text-transform: capitalize;
                }
            }
            & .payment-details{
                display: none;
                padding: 10px 0;

                & p{
                    font-size: 14px;
                }
            }
        }
    }

    & .checkout-btn{
        margin-top: 20px;
    }
}