/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/

// Slider Section 
.slider-section{
    background-color: #f6eaea;
}

// Single Slider 
.single-slider{
    
}

// Single Wrapper 
.slider-wrapper{
    position: relative;
}

// Single Social 
.slider-social{
    display: flex;
    position: absolute;
    bottom: 60px;
    left: 0;
    z-index: 4;

    @media #{$tablet-device}{
        bottom: 30px;
    }

    & a{
        font-size: 24px;
        line-height: 30px;
        margin: 0 25px;
        @media #{$tablet-device}{
            font-size: 18px;
        }
        &:hover{
            color: $dark;
        }
    }
}

// Slider Content 
.slider-content{
    position: relative;
    padding-left: 40px;
    z-index: 3;
    opacity: 0;

    @media #{$large-mobile}{
        padding-left: 0;
        margin-top: 50px;
    }

    & .shape-1{
        position: absolute;
        top: -57px;
        left: 0;
        z-index: -1;

        @media #{$tablet-device}{
            width: 80px;  
            top: -43px;   
        }
        @media #{$large-mobile}{
            width: 80px;  
            top: -43px;   
        }
    }
    & .sub-title{
        font-family: $font-family2;
        font-size: 30px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 28px;

        @media #{$tablet-device}{
            font-size: 24px;
        }
        @media #{$large-mobile}{
            font-size: 20px;
        }
    }
    & .main-title{
        font-size: 60px;
        font-weight: 700;
        color: $dark;
        line-height: 1.5;
        margin-bottom: 20px;
        @media #{$desktop-device}{
            font-size: 48px;
        }
        @media #{$tablet-device}{
            font-size: 40px;
        }
        @media #{$large-mobile}{
            font-size: 32px;
            margin-bottom: 10px;
        }
    }
    & .slider-btn{
        display: inline-block;
        font-size: 20px;
        font-family: $headings-font-family;
        line-height: 1;
        color: $dark;
        @media #{$tablet-device}{
            font-size: 18px;
        }
        @media #{$large-mobile}{
            font-size: 16px;
        }
        & span{
            position: relative;
            bottom: -3px;
            margin-left: 10px;
        }
        &:hover{
            color: $primary;
        }
    }
}

// Slider Images 
.slider-image{
    padding-top: 60px;
    position: relative;
    z-index: 3;
    opacity: 0;

    @media #{$large-mobile}{
        max-width: 300px;
        margin: 0 auto;
    }
    @media #{$small-mobile}{
        padding-top: 30px;
    }

    & > img{
        right: -25px;
    }

    & .slider-imgaes-shape{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        & .shape{
            position: absolute;

            &.shape-1{
                top: 40px;
                left: 36%;

                @media #{$large-mobile}{
                    left: 34%;
                    top: 10px;
                }

                & img{
                    @media #{$tablet-device}{
                        width: 160px;
                    }
                    @media #{$large-mobile}{
                        width: 160px;
                    }
                }
            }
            &.shape-2{
                top: 43.5%;
                left: 60px;

                @media #{$large-mobile}{
                    left: 0;
                }

                & img{
                    @media #{$tablet-device}{
                        width: 70px;
                    }
                    @media #{$large-mobile}{
                        width: 70px;
                    }
                }
            }
            &.shape-3{
                right: -105px;
                bottom: 26%;
                @media #{$tablet-device}{
                    right: -35px;
                } 
                @media #{$large-mobile}{
                    right: -20px;
                }                

                & img{
                    @media #{$tablet-device}{
                        width: 120px;
                    }
                    @media #{$large-mobile}{
                        width: 120px;
                    }
                }
            }
            &.shape-4{
                top: 47.5%;
                left: 105px;

                @media #{$large-mobile}{
                    left: 0;
                }

                & img{
                    @media #{$tablet-device}{
                        width: 70px;
                    }
                    @media #{$large-mobile}{
                        width: 70px;
                    }
                }
            }
        }

    }
}

// Animation Style 
.animation-style-01{
    &.swiper-slide-active{
        & .slider-content{
            opacity: 1;

            & .sub-title{
                animation-name: fadeInUpBig;
                animation-delay: 0.5s;
                animation-duration: 1.3s;
                animation-fill-mode: both;                
            }
            & .main-title{
                animation-name: fadeInUpBig;
                animation-delay: 1s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .slider-btn{
                animation-name: fadeInUpBig;
                animation-delay: 1.2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }

        & .slider-image{
            opacity: 1;

            & > img{
                animation-name: fadeInUp;
                animation-delay: 1s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
            }
        }
    }
}

// Slider Active 
.slider-active{
    & .swiper-button-next,
    & .swiper-button-prev{
        transition: all 0.3s linear;
        opacity: 0;
        visibility: hidden;

        &::after{
            display: none;
        }
        & span{
            font-size: 48px;
            line-height: 50px;
            color: $dark;
            position: relative;
            transition: all 0.3s linear;

            @media #{$large-mobile}{
                font-size: 32px;
                line-height: 35px;
            }

            &::before{
                position: absolute;
                content: '';
                width: 50px;
                height: 50px;
                background-color: #f0d4d4;
                border-radius: 50%;
                z-index: -1;
                right: -20px;

                @media #{$large-mobile}{
                    width: 35px;
                    height: 35px;
                    right: -15px;
                }
            }
        }
        &:hover{
            & span{
                color: $primary;
            }
        }
    }
    & .swiper-button-next{}
    & .swiper-button-prev{
        & span{
            transform: rotateY(180deg);
        }
    }

    &:hover{
        & .swiper-button-next{
            right: 5%;
            left: auto;
            opacity: 1;
            visibility: visible;
        }
        & .swiper-button-prev{
            left: 5%;
            right: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}


