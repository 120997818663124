/*--
/*  4.12 - Cart CSS
/*----------------------------------------*/

// Cart Table 
.cart-table{
    & .table{
        margin-bottom: 0;

        & thead{
            & tr{
                & th{
                    padding: 0px 0 15px;
                    text-align: center;
                    white-space: nowrap;
                    font-weight: 500;
                    color: $dark;
                    border-color: #bfdbf1 !important;

                    &:first-child{
                        text-align: left;
                    }
                    &:nth-of-type(2){
                        text-align: left;
                    }
                    &:last-child{
                        text-align: right;
                    }

                    &.product-image{
                        width: 120px;
                        min-width: 120px;
                    }
                    &.product-name{
                        min-width: 230px;
                    }
                    &.product-quantity{
                        min-width: 120px;
                    }
                    &.product-price{
                        min-width: 130px;
                    }
                    &.product-add-cart{
                        min-width: 150px;
                    }
                    &.product-action{
                        min-width: 60px;
                    }
                }
            }
        }
        & tbody{
            & tr{
                & td{
                    padding: 20px 0;
                    vertical-align: middle;
                    text-align: center;
                    border-color: #bfdbf1;

                    &:first-child{
                        text-align: left;
                    }
                    &:nth-of-type(2){
                        text-align: left;
                    }
                    &:last-child{
                        text-align: right;
                    }

                    &.product-image{}
                    &.product-name{}
                    &.product-quantity{}
                    &.product-price{}
                    &.product-action{}
                }
            }
        }
    }

    & .name{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        color: $dark;
        display: inline-block;
        transition: all 0.3s linear;

        @media #{$desktop-device}{
            font-size: 16px;
        }
        @media #{$tablet-device}{
            margin-top: 0px;
        }
        @media #{$large-mobile}{
            margin-top: 0px;
        }

        &:hover{
            color: $primary;
        }
    }

    & .product-quantity{
        margin-left: 10px;

        & button{
            width: 20px;
            height: 25px;
            border: 0;
            background-color: #d4e4f1;
            padding: 0;

            &.sub{
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }
            &.add{
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
            }
        }
        & input{
            height: 25px;
            width: 45px;
            padding: 0 10px;
            border: 0;
            background-color: #eaf1f9;
            font-size: 15px;
            font-weight: 500;
            color: $dark;
            text-align: center;
        }
    }

    & .product-price{
        & span{
            font-size: 18px;
            margin-right: 10px;
            letter-spacing: 0.5px;

            &:last-child{
                margin-right: 0;
            }

            &.old-price{
                font-weight: 400;
                color: #b8b8b8;
                text-decoration: line-through;
            }
            &.sale-price{
                color: $primary;
                font-weight: 600;
            }
        }
    }

    & .close{
        font-size: 18px;
        color: $dark;
        padding: 10px;
        transition: all 0.3s linear;

        &:hover{
            color: $primary;
        }
    }
}

// Cart Btn 
.cart-btn{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;

    & .cart-btn-left{

    }
    & .cart-btn-right{
        display: flex;
        flex-wrap: wrap;
    }

    & .btn{
        margin-top: 10px;
        margin-right: 15px;

        &:last-child{
            margin-right: 0;
        }
    }
    
}

// Cart Shipping Totals 
.cart-shipping,
.cart-totals{
    margin-top: 45px;

    & p{
        font-size: 15px;
    }
}

// Cart Title
.cart-title{
    & .title{
        font-size: 18px;
        font-weight: 500;
        font-family: $font-family-base;
    }

    & p{
        font-size: 15px;
    }
}

// Cart Total Table 
.cart-total-table{
    & .table{
        & tbody{
            & tr{
                & td{
                    font-size: 15px;

                    & .value{
                        font-weight: 500;
                        color: $dark;
                    }
                }
            }
        }
    }
}

// Empty Cart
.shipping-list{}



// Empty Cart
.empty-cart{
    & .empty-cart-title{        
        color: $dark;
        font-size: 30px;    
        font-family: $font-family-base;    

        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & .empty-cart-img{
        width: 110px;
        margin: 0 auto;
        padding-top: 25px;        
    }
    & p{
        font-size: 15px;
        text-transform: capitalize;
        margin-top: 30px;
        color: $dark;
        font-family: $headings-font-family;
    }
    & .btn{
        margin-top: 20px;
    }
}
