/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

.footer-section{
    background-color: #eaf1f9;
    position: relative;
    z-index: 3;

    & .footer-shape{
        position: absolute;
        bottom: 14px;
        right: 0;
        opacity: 0.1;
        z-index: -1;
        @media #{$desktop-device}{
            width: 220px;
        }

        @media #{$large-mobile}{
            bottom: 80px;
        }

        @media #{$tablet-device, $large-mobile} {
            width: 180px;
        }
    }
}

// Footer Copyright
.footer-copyright{
    background-color: #d4e4f1;
}

// Copyright payment
.copyright-payment{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4px;
    padding-bottom: 15px;

    @media #{$large-mobile}{
        display: block;
        text-align: center;
    }

    & .copyright-text{
        padding-top: 10px;

        & p{
            font-family: $headings-font-family;
            font-size: 14px;
        }
    }
    & .payment-method{
        padding-top: 10px;
    }
}

// Back to Top
.back-to-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    z-index: 99;
    text-align: center;
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(156,156,156,0.25);    
    transition: all 0.3s linear;
    color: $white;
    background-color: $dark;    

    &:hover{
        color: $white;
        background-color: $primary;
    }
}

