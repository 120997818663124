/*--
/*  2.3 - Form CSS
/*----------------------------------------*/

// Single Form 
.single-form{
    margin-top: 20px;

    & input:not([type="checkbox"]):not([type="radio"]),
    & textarea{
        width: 100%;
        height: 50px;
        padding: 0 15px;
        border: 1px solid #e1e1e1;
        font-size: 15px;
        transition: all 0.3s linear;
        border-radius: 3px;

        @media #{$large-mobile}{
            height: 40px;
        }

        & + input{
            margin-top: 15px;
        }

        &:focus{
            border-color: $primary;
            outline: none;
        }
    }

    & label{
        font-size: 15px;
    }

    & input[type="checkbox"]{
        display: none;

        & + label{
            padding-left: 22px;
            position: relative;
            cursor: pointer;
            
            & span{
                width: 14px;
                height: 14px;
                border: 1px solid #e1e1e1;
                position: absolute;
                display: block;
                top: 5px;
                left: 0;
                transition: all 0.3s linear;

                &::before{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    content: '\eed6';
                    font-family: IcoFont;
                    font-size: 14px;
                    line-height: 0.95;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s linear;
                    color: $white;
                }
            }
        }
        &:checked{
            & + label{
                & span{
                    background-color: $primary;
                    border-color: $primary;

                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

// Custom Radio
.radio{
    & input[type="radio"]{
        display: none;

        & + label{
            padding-left: 20px;
            position: relative;

            & span{
                width: 13px;
                height: 13px;
                border-radius: 50%;
                border: 1px solid $dark;
                display: block;
                position: absolute;
                top: 6px;
                left: 0;

                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: $dark;
                    top: 0;
                    left: 0;
                    transform: scale(0);
                    transition: all 0.3s linear;
                }
            }
        }
        &:checked{
            & + label{
                & span{
                    &::before{
                        transform: scale(0.7);
                    }
                }
            }
        }
    }
}


// Single Select 2 
.single-select2{
    margin-top: 20px;

    & span{
        display: block;

        &:focus{
            outline: none;
        }
    }

    & .form-select2{
        & .select2{
            width: 100%;            
        }
        & .select2-container--default {
            & .select2-selection--single {
                height: 50px;
                border-color: #ebebeb;
                transition: all 0.3s linear;
                border-radius: 3px;

                @media #{$large-mobile}{
                    height: 40px;
                }

                & .select2-selection__rendered {
                    width: 100%;
                    line-height: 48px;
                    border-radius: 0px;
                    padding-left: 15px;
                    padding-right: 28px;

                    @media #{$large-mobile}{
                        line-height: 38px;
                    }
                }
                & .select2-selection__arrow{
                    height: 35px;
                    width: 36px;
                }
            }
            &.select2-container--open{
                & .select2-selection--single {
                    border-color: $primary;
                }
            }
        }
    }
}


.select2-container--default{
    & .select2-results{
        &  > .select2-results__options {
            max-height: 200px;
            overflow-y: auto;
        } 
    }   

    & .select2-search--dropdown{
        & .select2-search__field {
            border-color: #ebebeb;
        }
    }
} 

.select2-dropdown{
    border-color: #ebebeb;
}