/*--
/*  3.1 - Header Top CSS
/*----------------------------------------*/

.header-top {
    background-color: #f6eaea;
    padding: 12px 0 14px;

    &.header-top-bg{
        background-color: #eaf1f9;
    }
}

// Header Top 
.header-top-info{
    display: flex;
    align-items: center;

    @media #{$tablet-device, $large-mobile}{
        justify-content: center;
    }

    & p{
        font-size: 15px;
        font-weight: 500;
        color: $dark;
        position: relative;
        margin-bottom: 0;

        @media #{$tablet-device, $large-mobile}{
            font-size: 14px;
        }

        & + p{
            &::before{
                content: '|';
                color: $dark;
                font-size: 16px;
                margin: 0 10px;
                position: relative;
                top: 0px;

                @media #{$tablet-device, $large-mobile}{
                    margin: 0 10px;
                }
            }
        }

        & a{
            color: $dark;

            &:hover{
                color: $primary;
            }
        }
    }
}

// Header Top lan Curr
.header-top-action{
    display: flex;
    justify-content: flex-end;

    @media #{$tablet-device, $large-mobile}{
        justify-content: center;
        padding-top: 5px;
    }

    & > *{
        position: relative;
        & + *{
            &::before{
                content: '|';
                color: $dark;
                font-size: 16px;
                margin: 0 8px;
                position: relative;
                top: -1px;

                @media #{$tablet-device, $large-mobile}{
                    margin: 0 10px;
                }
            }            
        }
    }

    & .action{
        border: 0;
        background: none;
        padding: 0;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        color: $dark;
        transition: all 0.3s linear;

        @media #{$tablet-device, $large-mobile}{
            font-size: 14px;
        }
    }

    & .dropdown{
        & .dropdown-menu{
            border: 0;
            padding: 0;
            box-shadow: 1px 1px 5px 0 rgba($black, 0.2);
            background: $white;
            padding: 8px;                    
            width: 150px;
            min-width: inherit;
            border-radius: 0;
            top: 34px !important;
            transform: translate3d(0px, 0px, 0px) !important;

            & > li{
                & a{
                    color: $body-color;
                    text-transform: capitalize;
                    text-decoration: none;
                    font-size: 13px;
                    padding: 0 10px;
                    line-height: 30px;
                    @include transition(0.3s);
                    display: block;

                    &:hover{
                        color: $primary;
                    }
                }
            }                
        }
    } 

    &:first-child{}
    &:last-child{
        & .dropdown{
            & .dropdown-menu{
                left: auto !important;
                right: 0;
            }
        }
    }

    & .header-top-lan{}
    & .header-top-curr{}    
}










