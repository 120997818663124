/*--
/*  4.9 - Shop CSS
/*----------------------------------------*/

.shop-to-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    & .shop-top-left{
        margin-top: 25px;

        & .nav{
            & li{
                & + li{
                    margin-left: 10px;
                }
                & a{
                    font-size: 22px;
                    color: $dark;
                    transition: all 0.3s linear;

                    &.active,
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }

    & .shop-top-right{
        margin-top: 25px;

        & .nice-select {
            float: none;
            height: 32px;
            line-height: 30px;
            border-radius: 0;
            padding-right: 25px;
            padding-left: 0;
            border: 0;
            display: inline-block;
            font-size: 15px;
            font-weight: 500;

            &::after{
                width: 7px;
                height: 7px;
                border-bottom: 1px solid #757575;
                border-right: 1px solid #757575;
            }

            & .current{}
            & .list{
                border-radius: 0;
                left: auto;
                right: 0;

                & .option {
                    cursor: pointer;
                    line-height: 30px;
                    min-height: 30px;                   
                    font-size: 14px;                    
                }
            }
        }
    }
}











