/*--
/*  2.1 - Button CSS
/*----------------------------------------*/

.btn {
    height: 50px;
    line-height: 46px;   

    @media #{$large-mobile}{
        height: 40px;
        line-height: 36px;
    }
        
    &:focus {
        box-shadow: none;
    }
}
@each $name, $value in $theme-colors {  
    .btn-#{$name} {
        @if $name == light {
            color: $headings-color;
        } @else if $name == warning {
            color: $headings-color;
        }@else if $name == info {
            color: $headings-color;
        } @else {
            color: $white;
        }
    } 
    .btn-hover-#{$name} {
        &:hover {
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $headings-color;
            } @else if $name == warning {
                color: $headings-color;
            } @else {
                color: $white;
            }
        }
    }
}
