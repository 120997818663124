/*--
/*  4.10 - Product Details CSS
/*----------------------------------------*/



// Product Details Wrapper 
.product-details-wrapper{}

// Product Details Images 
.product-details-images{
    position: relative;
    margin-top: 50px;

    & .gallery-top{
        & img{
            width: 100%;
        }
    }
    & .gallery-thumbs{
        width: 275px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        margin: 0 auto;

        @media #{$desktop-device, $tablet-device, $small-mobile}{
            width: 235px;
        }

        & img{
            border-radius: 50%;
            cursor: pointer;
            border: 1px solid transparent;
            transition: all 0.3s linear;
        }

        & .swiper-slide-thumb-active{
            & img{
                border-color: #a3a3a3;
            }
        }
    }
}

// Product Details Content 
.product-details-content{
    margin-top: 45px;

    & .product-category{
        & a{
            font-size: 15px;
            color: $body-color;
            font-weight: 500;
            &:hover{
                color: $primary;
            }
        }
    }
    & .product-title{
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 700;
        color: $dark;
        display: inline-block;
        transition: all 0.3s linear;

        @media #{$desktop-device}{
            font-size: 16px;
        }
        @media #{$tablet-device}{
            margin-top: 0px;
        }
        @media #{$large-mobile}{
            margin-top: 0px;
        }       
    }
    & .product-price-rating{
        display: flex;
    }
    & .product-price{
        & span{
            font-size: 18px;
            margin-right: 10px;
            letter-spacing: 0.5px;


            &:last-child{
                margin-right: 0;
            }

            &.old-price{
                font-weight: 400;
                color: #b8b8b8;
                text-decoration: line-through;
            }
            &.sale-price{
                color: $primary;
                font-weight: 600;
            }
        }
    }
    & .product-rating{
        position: relative;
        display: flex;

        &::before{
            content: '|';
            margin: 0 10px;
            color: #d2dfd5;
            font-size: 16px;
            line-height: 24px;
        }
        & .rating-star{
            position: relative;
            display: inline-block;

            &::before{
                content: '\f005\f005\f005\f005\f005';
                font-family: FontAwesome;
                letter-spacing: 7px;
                color: #b8b8b8;
                font-size: 11px;
            }
            & .star{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                display: inline-block;
                overflow: hidden;
                &::before{
                    content: '\f005\f005\f005\f005\f005';
                    font-family: FontAwesome;
                    letter-spacing: 7px;
                    color: $primary;
                    font-size: 11px;
                }
            }
        }
    }
    & p{
        margin-top: 21px;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 1.86;
        font-weight: 500;
    }
    & .product-info{
        padding-top: 10px;

        & .single-info{
            display: flex;
            margin-top: 15px;
            align-items: center;

            & span{
                font-size: 15px;
                font-weight: 500;
                display: inline-block;
                letter-spacing: 0.5px;
            }
            & .label{
                color: $primary;
            }
            & .value{
                color: $dark;
                margin-left: 5px;
            }
            & .size{
                margin-left: 5px;

                & li{
                    & + li{
                        margin-left: 10px;
                    }
                    color: $dark;
                    font-size: 15px;
                    font-weight: 500;
                    display: inline-block;
                    cursor: pointer;

                    &.active{
                        color: $primary;
                    }
                }
            }
            & .product-quantity{
                margin-left: 10px;

                & button{
                    width: 20px;
                    height: 25px;
                    border: 0;
                    background-color: #d4e4f1;
                    padding: 0;

                    &.sub{
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                    }
                    &.add{
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
                & input{
                    height: 25px;
                    width: 45px;
                    padding: 0 10px;
                    border: 0;
                    background-color: #eaf1f9;
                    font-size: 15px;
                    font-weight: 500;
                    color: $dark;
                    text-align: center;
                }
            }
        }
    }
    & .product-cart-favourate{
        padding-top: 30px;
        display: flex;

        & .product-cart{
            margin-top: 10px;
        }
        & .product-favourate{
            margin-top: 10px;
            margin-left: 30px;

            & .favourate{
                height: 40px;
                line-height: 40px;
                font-size: 22px;
                color: #636464;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

// Product Details Tabs 
.product-details-tabs{
    & .nav{
        & .nav-item{
            & + .nav-item{
                & a{
                    border-left: 1px solid #bfdbf1;
                }
            }
            & a{
                line-height: 50px;
                font-size: 18px;
                font-weight: 700;
                font-family: $headings-font-family;
                color: $dark;
                background-color: #eaf1f9;
                display: block;
                transition: all 0.3s linear;

                @media #{$large-mobile}{
                    font-size: 16px;
                }
                @media #{$small-mobile}{
                    font-size: 12px;
                    line-height: 40px;
                }

                &.active{
                    background-color: $primary;
                    color: $white;
                    border-color: $primary;
                }
            }
        }
    }

    & .product-description{
        padding-top: 45px;

        & p{
            font-size: 15px;
            line-height: 1.86;
            font-weight: 500;
        }
    }

    & .information{
        & .information-content{
            margin-top: 45px;
        }
        & .title{
            font-size: 18px;
            font-weight: 500;
            font-family: $font-family-base;
            display: inline-block;
            position: relative;
            padding-bottom: 5px;

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background-color: #d2dfd5;
                left: 0;
                bottom: 0;
            }
        }
        & ul{
            margin-top: 20px;

            & li{
                margin-top: 5px;
                font-size: 15px;
                line-height: 1.86;
                font-weight: 500;
            }
        }
    }

    & .reviews{
        margin-top: 45px;

        & .reviews-title{
            font-size: 18px;
            font-weight: 500;
            font-family: $font-family-base;
            position: relative;
            display: inline-block;
            padding-bottom: 5px;

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background-color: #d2dfd5;
                left: 0;
                bottom: 0;
            }
        }

        & .reviews-comment{
            margin-top: 45px;
            & .comment-items{}

            & .single-comment{
                display: flex;
                margin-top: 35px;

                & .comment-thumb{
                    & img{
                        border-radius: 50%;
                    }
                }
                & .comment-content{
                    flex: 1;
                    padding-left: 20px;
                    margin-top: -2px;

                    & .comment-name-date{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;

                        & .name{
                            font-family: $font-family-base;
                            font-size: 18px;
                            font-weight: 500;
                            margin-bottom: 0;

                            @media #{$small-mobile}{
                                font-size: 14px;
                            }

                            &::after{
                                content: '-';
                                margin: 0 8px;
                            }
                        }
                        & .date{
                            font-size: 15px;
                            font-weight: 500;

                            @media #{$small-mobile}{
                                font-size: 13px;
                            }
                        }
                    }
                    & .comment-rating{
                        position: relative;
                        display: inline-block;
                        margin-bottom: 2px;

                        &::before{
                            content: '\f006\f006\f006\f006\f006';
                            font-family: FontAwesome;
                            letter-spacing: 4px;
                            color: $primary;
                            font-size: 12px;
                        }
                        & .rating-star{
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            display: inline-block;
                            overflow: hidden;
                            &::before{
                                content: '\f005\f005\f005\f005\f005';
                                font-family: FontAwesome;
                                letter-spacing: 4px;
                                color: $primary;
                                font-size: 12px;
                            }
                        }
                    }
                    & p{
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }
        }

        & .reviews-form{
            margin-top: 45px;

            & .form-title{
                font-size: 18px;
                font-weight: 500;
                font-family: $font-family-base;
            }
            & .reviews-rating{
                display: flex;
                align-items: center;
                & label{
                    font-size: 15px;
                    font-weight: 500;
                }
                & .rating{
                    display: flex;
                    margin-left: 5px;
                    
                    & li{
                        margin: 0 2px;
                        color: $primary;
                        cursor: pointer;

                        & i{
                            transition: all 0.3s linear;
        
                            &.hover{
                                &::before {
                                    content: "\f005" !important;
                                }
                            }
                        }
                    }
                }
            }
            & .single-form{
                                
                & textarea{
                    height: 150px;
                    padding-top: 8px;
                    resize: none;
                }
                & .btn{
                    width: 140px;
                }
            }
        }
    }
}







