/*-----------------------------------------------------------------------------------

    Template Name: Template Name
    Version: 1.0

-------------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
	02. Component CSS
		2.1 - Button CSS
		2.2 - Blockquote CSS
		2.3 - Form CSS
	03. Header CSS
		3.1 - Header Top CSS
		3.2 - Header Bottom CSS
		3.3 - Header Mobile CSS
	04. Pages CSS (All Page Styles)
		4.1 - Slider CSS
		4.2 - Product CSS
		4.3 - Banner CSS
		4.4 - Blog CSS
		4.5 - Newsletter CSS
		4.6 - Page Banner CSS
		4.7 - About CSS
		4.9 - Shop CSS
		4.10 - Product Details CSS
		4.11 - Blog Details CSS
		4.12 - Cart CSS
		4.13 - Checkout CSS
		4.14 - My Account CSS
		4.15 - Login & Register CSS
		4.16 - Contact CSS
	05. Widget CSS	
		5.1 - Footer Widget
		5.2 - Sidebar Widget
	06. Footer CSS	

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variables";
@import "custom-variables";
@import "./bootstrap/bootstrap";

@import "common";
@import "component";
@import "header";
@import "page";
@import "widget";
@import "footer";


