/*--
/*  2.2 - Blockquote CSS
/*----------------------------------------*/

blockquote {
    background-color: #eaf1f9;
    padding: 30px 45px;
    padding-right: 90px;
    border-left: 4px solid $primary;
    position: relative;
    margin-top: 45px;
    margin-bottom: 0;

    @media #{$large-mobile}{
        padding: 20px 30px;
        padding-right: 70px;
    }
    @media #{$small-mobile}{
        padding: 10px 20px;
        padding-top: 50px;
    }

    &::before{
        content: "";
        position: absolute;
        top: 20px;
        right: 35px;
        background-image: url(../images/quote.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 47px;
        height: 42px;

        @media #{$large-mobile}{
            width: 37px;
            height: 32px;
            right: 25px;
        }
        @media #{$small-mobile}{
            right: 10px;
        }

        @media #{$small-mobile}{
            font-size: 30px;
        }
    }   

    & p {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.86;
    }
}