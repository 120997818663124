/*--
/*  4.17 - Brand CSS
/*----------------------------------------*/

// Single Brand
.single-brand{
    text-align: center;
}









