/*--
/*  4.3 - Banner CSS
/*----------------------------------------*/


// Banner Items
.banner-item{
    margin-top: 30px;
    position: relative;

    & a{
        display: block;
        position: relative;
        overflow: hidden;

        & img{
            width: 100%;
            transition: all 0.3s linear;
        }
    }
    & .banner-text{
        position: absolute;
        padding: 25px 30px;

        @media #{$small-mobile}{
            padding: 10px 15px;
        }

        & .sub-title{
            font-size: 18px;
            color: $primary;
            display: block;
            font-family: $headings-font-family;
            
            @media #{$desktop-device}{
                font-size: 16px;
            }
            @media #{$tablet-device}{
                font-size: 16px;
                margin-bottom: 3px;
            }
            @media #{$small-mobile}{
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
        & .title {
            font-size: 36px;
            color: $dark;
            margin-bottom: 0px;

            @media #{$desktop-device}{
                font-size: 24px;
            }
            @media #{$tablet-device}{
                font-size: 24px;
            }
            @media #{$small-mobile}{
                font-size: 22px;
            }
        }
        & .code{
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1px;
            margin-bottom: 0;

            @media #{$large-mobile}{
                font-size: 14px;
            }

            & span{
                color: $primary;
            }
        }
        & .banner-btn{
            font-size: 16px;
            font-family: $headings-font-family;
            color: $dark;

            @media #{$large-mobile}{
                font-size: 16px;
            }
            @media #{$small-mobile}{
                font-size: 14px;
            }

            & span{
                position: relative;
                top: 3px;
            }

            &:hover{
                color: $primary;
            }
        }
    }
    &.banner-01{
        & .banner-text{
            top: 0;
            right: 0;
            text-align: right;
        }
    }
    &.banner-02{
        & .banner-text{
            bottom: 0;
            right: 0;
            text-align: right;
        }        
    }
    &.banner-03{
        & .banner-text{
            top: 0;
            left: 0;
        }
    }
    &.banner-04{
        & .banner-text{
            top: 0;
            left: 0;
            text-align: center;
            width: 100%;

            & .sub-title{
                font-size: 14px;
            }
            & .title {
                font-size: 28px;

                @media #{$desktop-device}{
                    font-size: 24px;
                }
            }
        }
    }
    &.banner-05{
        & .banner-text{
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 45%;
            @media #{$desktop-device}{
                width: 50%;
            }
            @media #{$small-mobile}{
                width: 60%;
            }

            & .sub-title{
                font-family: $font-family2;
                margin-bottom: 4px;
            }
            & .title {
                font-size: 28px;
                margin-bottom: 5px;
                @media #{$desktop-device}{
                    font-size: 24px;
                }
                @media #{$small-mobile}{
                    font-size: 18px;
                }
            }
            & .code{
                margin-bottom: 5px;
            }
        }
    }
    &.banner-06{
        margin-top: -6px;
        @media #{$desktop-device}{
            margin-top: 0px;
        }
        @media #{$tablet-device,$large-mobile}{
            margin-top: 30px;
        }

        & .banner-text{
            top: 50%;
            transform: translateY(-50%);
            left: 70px;
            width: 50%;

            @media #{$small-mobile}{
                padding: 15px;
                width: 60%;
                left: 20px;
            }

            & .sub-title{
                font-family: $font-family2;
                margin-bottom: 5px;
            }
            & .title {
                font-size: 28px;
                margin-bottom: 5px;

                @media #{$desktop-device}{
                    font-size: 24px;
                }
                @media #{$small-mobile}{
                    font-size: 20px;
                }
            }
            & .code{
                margin-bottom: 5px;
            }
        }
    }

    &:hover{
        & a{
            & img{
                transform: scale(1.05);
            }
        }
    }
}

// Custom banner Cal 
.custom-banner-col-1{
    @media #{$tablet-device}{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 51.8%;
    }
}
.custom-banner-col-2{
    @media #{$tablet-device}{
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 48.2%;
    }
}






