/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Maven+Pro:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

/* Material Design Icons */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
  
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;    
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


/*-- Common Style --*/
*,
*::after,
*::before {
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    position: relative;
    visibility: visible;
    line-height: 1.5;
    
    color: "";
    &[dir="rtl"] {
        text-align: right;
    }
}

a,
button {
    transition: $transition-base;
    outline: 0;

    &:focus{
        outline: 0;
    }
}

a{
    text-decoration: none;
    color: inherit;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

ul,ol{
    padding: 0;
    list-style: none;
    margin: 0;
}

img{
    max-width: 100%;
}


/* Section Style */
.section{
    float: left;
    width: 100%;
}


/* Section Spacing */
.section-padding {
    padding-top: 100px;
    @media #{$tablet-device} {
        padding-top: 80px;
    }
    @media #{$large-mobile} {
        padding-top: 60px;
    }
}
.section-padding-02 {
    padding-top: 100px;
    padding-bottom: 100px;
    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-margin {
    margin-top: 100px;
    @media #{$tablet-device} {
        margin-top: 80px;
    }
    @media #{$large-mobile} {
        margin-top: 60px;
    }
}
.section-margin-02 {
    margin-top: 100px;
    @media #{$tablet-device} {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    @media #{$large-mobile} {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}


/* Section TItle */
.section-title{
    position: relative;

    & .title{
        font-size: 38px;
        margin-bottom: 0;
        color: $dark;

        @media #{$desktop-device}{
            font-size: 30px;
        }
        @media #{$tablet-device}{
            font-size: 24px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }

    &.line-1{
        &::before{
            position: absolute;
            content: '';
            width: 555px;
            height: 1px;
            background-color: #f0d4d4;
            top: 50%;
            transform: translateY(-50%);
            left: 280px;

            @media #{$desktop-device}{
                left: 225px;
                width: 380px;
            }
            @media #{$tablet-device}{
                left: 180px;
                width: 225px;
            }
            @media #{$large-mobile}{
                display: none;
            }
        }
    }
}

/* tab Content */
.tab-content{
    & .tab-pane{
        display: block;
        overflow: hidden;
        height: 0;
        visibility: hidden;
        max-width: 100%;
        opacity: 0;
        
        &.active{
            height: auto;
            visibility: visible;
            opacity: 1;
            overflow: visible;
        }
    }
}

/* Page pagination */
.page-pagination{
    padding-top: 45px;

    & .pagination{
        & .page-item{
            margin: 0 10px;

            & .page-link{
                width: 30px;
                height: 30px;
                line-height: 28px;
                text-align: center;
                font-size: 15px;
                font-family: $headings-font-family;
                color: $dark;
                border: 1px solid #e2e2e2;
                padding: 0;
                border-radius: 50%;

                &:focus{
                    box-shadow: none;
                }

                &.active,
                &:hover{
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                }
            }

            &:nth-last-of-type(2){
                width: auto;
                color: #959595;
                font-family:  $headings-font-family;
                border: 0;
                font-size: 40px;
                margin: 0 5px;
                line-height: 0.2;
            }
        }
    }
}
