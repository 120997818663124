/*--
/*  4.2 - Product CSS
/*----------------------------------------*/


// Editor Pick Wrapper
.editor-pick-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$large-mobile}{
        display: block;
    }
}

// Product Tabs Menu 
.product-tabs-menu{
    @media #{$large-mobile}{
        padding-top: 16px;
    }
    & .nav{
        & .nav-item{
            margin-right: 37px;
            &:last-child{
                margin-right: 0;
            }
            & .nav-link{
                padding: 0;
                font-weight: 600;
                color: #a3a3a3;
                transition: all 0.3s linear;
                font-size: 18px;

                @media #{$tablet-device}{
                    font-size: 15px;
                }
                @media #{$large-mobile}{
                    font-size: 15px;
                }

                &:hover,
                &.active{
                    color: $dark;
                }
            }
        }
    }
}

// Product Wrapper
.product-wrapper{
    padding-top: 10px;
}

// Single Product
.single-product{
    margin-top: 30px;

    & .product-image{
        position: relative;
        & a{
            display: block;
            position: relative;
        }
        & img{
            width: 100%;
            transition: all 0.3s linear;

            &.hover-image{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: all 0.6s linear;
                opacity: 0;
                visibility: hidden;
            }
        }
        & .product-label{
            position: absolute;
            top: 20px;
            left: 30px;
            font-family: $headings-font-family;
            font-size: 15px;
            font-weight: 400;
            z-index: 8;

            &.percentage{
                color: $dark;
            }
            &.sold-out{
                color: $primary;
            }
            &.hot{
                color: #fe0006;
            }
        }
        & .product-available{
            position: absolute;
            bottom: 13px;
            left: 20px;
            font-size: 15px;
            font-weight: 500;
            color: $dark;
            letter-spacing: 0.8px;
        }
        & .product-slide{
            & .swiper-slide{
                width: 100% !important;
            }
            & .swiper-pagination {
                & .swiper-pagination-bullet{
                    background-color: #c2c2c2;
                    opacity: 1;

                    &.swiper-pagination-bullet-active{
                        background-color: $dark;
                    }
                }
            }
        }
        & .product-gallery-preview{
            & .swiper-slide{
                width: 100% !important;
            }
        }
        & .product-gallery-thumb{
            position: absolute;
            width: 170px;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            z-index: 4;

            & .single-thumb{
                cursor: pointer;
                padding: 0 6px;
                width: 33.33%;
            }
        }
    }
    & .product-content{
        & .product-category-action{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 18px;
        }
        & .product-category{
            line-height: 1;
            @media #{$small-mobile}{
                margin-bottom: 3px;
            }

            & a{
                font-size: 15px;
                color: $body-color;
                letter-spacing: 0.5px;
                &:hover{
                    color: $primary;
                }
            }
        }
        & .product-action{
            line-height: 1;
            
            & .action{
                margin-right: 15px;
                font-size: 16px;
                color: #636464;
                
                &:hover{
                    color: $primary;
                }

                &:last-child{
                    margin-right: 0;
                }
            }
        }
        & .product-title{
            margin-bottom: 0;            

            & a{
                font-size: 18px;
                font-weight: 700;
                color: $dark;
                display: inline-block;
                transition: all 0.3s linear;

                @media #{$desktop-device}{
                    font-size: 16px;
                }
                
                &:hover{
                    color: $primary;
                }
            }
        }
        & .product-price{
            & span{
                font-size: 18px;
                margin-right: 10px;
                letter-spacing: 0.5px;

                &:last-child{
                    margin-right: 0;
                }

                &.old-price{
                    font-weight: 400;
                    color: #b8b8b8;
                    text-decoration: line-through;
                }
                &.sale-price{
                    color: $primary;
                    font-weight: 600;
                }
            }
        }
        & p{
            margin-top: 20px;
            margin-bottom: 25px;
            max-width: 530px;
            font-size: 15px;
            line-height: 1.86;
            font-weight: 500;

            @media #{$large-mobile}{
                margin-top: 10px;
            }
        }
    }

    &.product-list{
        display: flex;
        align-items: center;
        background-color: #eaf1f9;

        @media #{$large-mobile}{
            display: block;
        }

        & .product-image{
            width: 270px;

            @media #{$large-mobile}{
                width: 100%;
            }
        }
        & .product-content{
            flex: 1;
            padding: 40px 45px;

            @media #{$tablet-device, $large-mobile}{
                padding: 25px 30px;
            }
            
            & .product-category{
                line-height: 1;
    
                & a{
                    font-size: 15px;
                    color: $body-color;
                    letter-spacing: 0.5px;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }

    &:hover{
        & .product-image{
            & a{
                & img{
                    &:not(:last-child) {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &.hover-image{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

// New Arrivals Wrapper
.new-arrivals-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$large-mobile}{
        display: block;
    }
}

// Product Countdown 
.product-countdown{
    display: flex;

    @media #{$large-mobile}{
        margin-top: 25px;
    }

    & .countdown-title{
        & .title{
            font-size: 16px;
            font-weight: 500;
            font-family: $font-family-base;
            color: $primary;
            background-color: #d4e4f1;
            letter-spacing: 3px;
            display: inline-block;
            line-height: 33px;
            padding: 0 16px;
            margin-bottom: 0;

            @media #{$small-mobile}{
                font-size: 14px;
                letter-spacing: 1px;
            }
        }
    }
    & .countdown-count{
        background-color: #f0d4d4;
        padding: 0 12px;
        display: flex;

        & .single-count{
            position: relative;

            & + .single-count{
                &::before{
                    content: ':';
                    margin: 0 8px;
                    color: $dark;
                    font-weight: 600;
                }
            }
            & span{
                color: $dark;
                font-size: 16px;
                font-weight: 500;
                font-family: $font-family-base;
                letter-spacing: 1px;
                line-height: 33px;

                @media #{$small-mobile}{
                    font-size: 15px;
                }
            }
        }
    }
}





