/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/

// Sidebar Widget 
.sidebar-widget{}

// Widget Item
.widget-item{
    margin-top: 50px;
}

// Widget Title 
.widget-title{
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    background-color: #eaf1f9;
    line-height: 50px;
    padding: 0 15px;
    margin-bottom: 30px;
}

// Widget Link 
.widget-link{
    margin-top: -2px;
    padding: 0 15px;

    & ul{
        & li{
            position: relative;
            margin-top: 12px;
            & a{
                font-size: 15px;
                font-weight: 500;
                color: #3b3b3b;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }

            & .category-toggle{
                position: absolute;
                top: 0;
                right: 0;

                & .add,
                & .remove{
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 24px;
                    line-height: 22px;
                    color: #000;
                    display: inline-block;
                    cursor: pointer;
                }
                & .add{
                    display: none;
                }
                & .remove{
                    display: block;
                }

                &.collapsed{
                    & .add{
                        display: block;
                    }
                    & .remove{
                        display: none;
                    }
                }
            }

            & ul{
                & li{
                    & a{
                        padding-left: 7%;
                    }

                    & ul{
                        & li{
                            & a{
                                padding-left: 13%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Widget Color 
.widget-color{
    padding: 0 15px;

    & .color-list{
        display: flex;
        flex-wrap: wrap;

        & li{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            border: 1px solid #b8b8b8;
            cursor: pointer;
            margin-top: 5px;

            &:last-child{
                margin-right: 0;
            }

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                border: 3px solid $white;
                border-radius: 50%;
                top: 0;
                left: 0;
            }

            &::after{
                content: '\eed6';
                font-family: IcoFont !important;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                text-align: center;
                color: $white;
                opacity: 0;
                visibility: hidden;
            }
            &.active{
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

// Widget Banner 
.widget-banner{
    display: block;
    position: relative;

    &::before {
        background:rgba($white,0.2);
        bottom:0;
        content:"";
        left:50%;
        position:absolute;
        right:51%;
        top:0;
        opacity:1
    }
    &::after {
        background:rgba($white,0.2);
        bottom:50%;
        content:"";
        left:0;
        position:absolute;
        right:0;
        top:50%;
        opacity:1
    }

    & img{
        width: 100%;
    }

    &:hover::before {
        left:0;
        right:0;
        opacity:0;
        transition:all 900ms ease-in
    }
    &:hover::after {
        top:0;
        bottom:0;
        opacity:0;
        transition:all 900ms ease-in
    }
}

// Widget Search 
.widget-search{
    padding: 0 15px;
    position: relative;

    & input{
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        border: 0;
        height: 40px;
        border-bottom: 1px solid #d2dfd5;
        padding-right: 30px;

        &:focus{
            outline: none;
        }
    }
    & button{
        position: absolute;
        top: 0;
        right: 15px;
        height: 40px;
        border: 0;
        padding: 0;
        background: none;
    }
} 

// Widget Post 
.widget-post{
    padding: 0 15px;

    & .single-post{
        display: flex;

        & + .single-post{
            margin-top: 30px;
        }

        & .post-thumb{
            & a{
                display: block;
                & img{
                    width: 70px;
                    height: 62px;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        & .post-content{
            flex: 1;
            padding-left: 25px;

            & .title{
                margin-bottom: 2px;

                & a{
                    font-size: 15px;
                    font-weight: 700;
                    color: $dark;
                    transition: all 0.3s linear;
                    margin-bottom: 5px;

                    &:hover{
                        color: $primary;
                    }
                }
            }
            & .date{
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
}

// Widget Tags
.widget-tags{
    padding: 0 15px;

    & ul{
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        

        & li{
            margin: 0px 10px 10px;
            & a{
                font-size: 15px;
                font-weight: 500;
                color: $dark;
                transition: all 0.3s linear;
               

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}



