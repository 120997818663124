/*--
/*  3.2 - Header Bottom CSS
/*----------------------------------------*/

// Header Bottom 
.header-bottom{
    background-color: $white;
}

// header Logo 
.header-logo{}

// Primary Menu 
.primary-menu{
    & ul{
        display: flex;
        justify-content: center;

        & > li{
            position: relative;

            & > a{
                font-family: $headings-font-family;
                font-size: 18px;
                font-weight: 500;
                color: $dark;
                padding: 43px 29px;
                display: block;
                transition: all 0.3s linear;

                @media #{$desktop-device}{
                    font-size: 16px;
                }
            }

            &:hover{
                & > a{
                    color: $primary;
                }
                & .mega-sub-menu,
                & > .sub-menu{
                    opacity: 1;
                    visibility: visible;
                    transform: rotateX(0);
                }
            }

            &:nth-last-child(2),
            &:nth-last-child(2){
                & > .sub-menu{
                    & li{
                        & > .sub-menu{
                            @media #{$desktop-device}{
                                left: auto;
                                right: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    // Sub Menu 
    & .sub-menu{
        position: absolute;
        top: 100%;
        left: 0;
        width: 240px;
        background-color: #eaf1f9;
        display: block;
        z-index: 9;
        padding: 8px 0;
        box-shadow: 0px 2px 8px rgba($dark, 0.1);
        transition: all 0.3s linear;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-90deg);
        opacity: 0;
        visibility: hidden;

        & > li{
            & a{
                padding: 0;
                font-size: 14px;
                padding: 8px 20px;
            }            
            &:hover{
                & > a{
                    padding-left: 25px;
                }

                & .sub-menu{
                    opacity: 1;
                    visibility: visible;
                }
            }
             // Sub Menu 
            & .sub-menu{
                left: 100%;
                top: 0;
            }
        }
    }

    // Mega Sub Menu 
    & .mega-sub-menu{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1170px;
        background-color: #eaf1f9;
        z-index: 9;
        box-shadow: 0px 2px 8px rgba($dark, 0.1);
        transition: all 0.3s linear;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-90deg);
        padding: 30px 50px;
        justify-content: flex-start;
        opacity: 0;
        visibility: hidden;

        @media #{$desktop-device}{
            width: 930px;
        }

        & > li{
            width: 33.33%;

            & > a{
                padding: 0;
                font-size: 16px;
                text-decoration: underline;
            }
        }

        // Mega Item 
        & .menu-item{
            display: block;
            padding-top: 10px;

            & li{
                margin-top: 10px;
                & a{
                    padding: 0;
                    font-size: 14px;
                }
            }
        }
    }
}

// Header Action 
.header-action{
    display: flex;
    justify-content: flex-end;
    
    & > * {
        & + *{
            margin-left: 22px;

            @media #{$desktop-device}{
                margin-left: 18px;
            }
            @media #{$large-mobile}{
                margin-left: 10px;
            }
            @media #{$small-mobile}{
                margin-left: 0px;
            }
        }
    }
    & .action{
        font-size: 24px;
        color: $dark;
        position: relative;
        transition: all 0.3s linear;
        padding: 0px 8px;
        display: block;

        @media #{$desktop-device}{
            font-size: 20px;
        }
        @media #{$large-mobile}{
            font-size: 20px;
        }

        & .num{
            width: 22px;
            height: 22px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            color: $white;
            background-color: $dark;
            font-size: 10px;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;

            @media #{$large-mobile}{
                font-size: 9px;
                width: 19px;
                height: 19px;
                line-height: 18px;
                top: -5px;
            }
        }

        &:hover{
            color: $primary;
        }
    }

    & .dropdown{
        & .dropdown-menu{
            min-width: 340px;
            width: 100%;
            border-radius: 0;
            border: 0;
            background-color: $white;
            padding: 5px 25px 25px;
            top: 170% !important;
            transform: translate3d(0, 0, 0px) !important;
            left: auto !important;
            right: 0 !important;
            box-shadow: 1px 1px 5px 0 rgba($black, 0.2);
            
            @media #{$tablet-device, $large-mobile}{
                min-width: 200px;
            }

            & .dropdown-language,
            & .dropdown-currency{
                padding-top: 20px;
            }
            & .selected{
                display: flex;
                align-items: center;
                font-weight: 700;
                color: $dark;                
                cursor: pointer;
                line-height: 20px;
                font-size: 14px;
                text-transform: uppercase;
                
                & img{
                    margin-right: 5px;
                }
            }
            & ul{
                & > li{
                    & > a{
                        padding: 0 15px;
                        color: $dark;     
                        line-height: 30px;
                        font-size: 12px;   
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        
                        & img{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        & .dropdown-cart{
            & .cart-price{
                overflow: hidden;
                padding: 25px 0;
                & .cart-total,
                & .cart-subtotals{
                    & .price-inline{
                        display: flex;
                        justify-content: space-between;

                        & span{
                            font-weight: 400;
                            color: #888888;
                            font-size: 14px;   
                            line-height: 1.785;    
                            
                            &.label{
                                color: $dark;
                            }
                        }
                    }
                }
                & .cart-total{
                    & .price-inline{
                        & span{
                            font-weight: 500;  
                            color: $dark;
                        }
                    }
                }
            }

            & .checkout-btn{
                & .btn{
                    border-radius: 0;
                    border: 2px solid #eeeeee;
                    height: 50px;
                    line-height: 46px;
                    font-size: 13px;
                    font-weight: 500;
                }
                @each $name, $value in $theme-colors {
                    .btn-hover-#{$name} {
                        &:hover {
                            border-color: $value;                           
                        }
                    }
                }
            }
        }
    }
}

// Cart content 
.cart-content{
    max-height: 417px;
    overflow: auto;

    & .cart-items{
        & li{
            padding: 20px 0;
            border-bottom: 1px solid #ebebeb;
            overflow: hidden;
        }
    }
}

// Single Cart Items 
.single-cart-item{
    display: flex;

    & .cart-thumb{
        & a{
            & img{}
        }
    }
    & .cart-item-content{
        padding-left: 15px;
        padding-right: 20px;
        flex: 1;
        overflow: hidden;
        position: relative;

        & .product-name{
            color: $dark;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;     
            text-decoration: none;            
        }
        & .product-price{
            display: block;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;            
        }
        & .attributes-content{
            display: block;
            font-size: 16px;
            line-height: 20px;
            color: #747474;
            margin-top: 5px;        

            & span{
                font-size: 14px;
                display: block;

                & strong{
                    font-weight: 400;
                }
            }
        }
        & .remove-cart{
            text-decoration: none;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 18px;
        }
    }
}

// Header Sticky 
.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    animation: sticky 1s;
    box-shadow: 2px 4px 8px rgba($dark, 0.15);
    background-color: $white;

    & .primary-menu{
        & ul{
            & > li{
                & > a{
                    padding: 25px 29px;
                }

                & .sub-menu{
                    & > li{
                        & > a{
                            padding: 8px 20px;
                        }
                        &:hover{
                            & > a{
                                padding-left: 25px;
                            }
                        }
                    }
                }
                & .mega-sub-menu{
                    & > li{
                        & a{
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    & .header-action{
        & .dropdown{
            & .dropdown-menu{
                top: 158% !important;
            }
        }
    }
}
@-webkit-keyframes sticky {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}

@keyframes sticky {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}