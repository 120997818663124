/*--
/*  4.6 - Page Banner CSS
/*----------------------------------------*/

// Page Banner 
.page-banner{
    background-color: #eaf1f9;
    padding-top: 112px;
    padding-bottom: 115px;

    @media #{$tablet-device} {
        padding-top: 75px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile} {
        padding-top: 55px;
        padding-bottom: 60px;
    }

    &.about-banner{
        padding-bottom: 242px;

        @media #{$tablet-device}{
            padding-bottom: 207px;
        }
        @media #{$large-mobile} {
            padding-bottom: 102px;
        }
    }
}

// Page Banner Wrapper
.page-banner-wrapper{
    & .title{
        font-size: 60px;
        font-weight: 700;
        color: $dark;

        @media #{$tablet-device}{
            font-size: 40px;
        }
        @media #{$large-mobile}{
            font-size: 28px;
        }
    }
    & .breadcrumb{
        margin-bottom: 0;
        & .breadcrumb-item{
            font-family: $headings-font-family;
            color: $dark;
            font-size: 16px;

            @media #{$large-mobile}{
                font-size: 14px;
            }

            & + .breadcrumb-item{
                &::before{
                    color: $dark;
                }
            }
            & a{}
        }
    }
}






