/*--
/*  4.11 - Blog Details CSS
/*----------------------------------------*/

// Blog Details Wrapper 
.blog-details-wrapper{
    margin-top: 50px;

    & img{
        width: 100%;
        margin-bottom: 32px;
    }

    & .blog-meta{
        display: flex;
        margin-bottom: 13px;

        & > * {
            font-size: 15px;
            font-weight: 500;
            color: $primary;

            & + * {
                &::before{
                    content: '/';
                    margin: 0 8px;
                }
            }
        }
    }
    & .title{
        font-size: 18px;
        font-weight: 700;
        color: $dark;
        margin-bottom: 25px;

        @media #{$desktop-device}{
            font-size: 16px;
        }
        @media #{$tablet-device}{
            font-size: 16px;
        }
        @media #{$large-mobile}{
            font-size: 15px;
        }        
    }
    & p{
        line-height: 1.86;
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;
    }

    & .details-dec{
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;

        @media #{$large-mobile}{
            display: block;
        }

        & p{
            width: 58%;
            margin-top: 30px;
            padding-right: 30px;

            @media #{$large-mobile}{
                width: 100%;
                padding-right: 0;
            }
        }
        & img{
            width: 42%;
            margin-top: 30px;

            @media #{$large-mobile}{
                width: 100%;
            }
        }
    }
}

// Blog Details Tags / Share 
.blog-details-tags-share{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;

    & .title{
        font-size: 18px;
        color: $primary;
        font-family: $headings-font-family;
    }

    & .blog-details-tags{
        display: flex;
        margin-top: 15px;

        & .tag-list{
            display: flex;
            margin-left: 10px;
            flex-wrap: wrap;
            flex: 1;

            & li{
                position: relative;

                & + li{
                    &::before{
                        content: '/';
                        font-size: 15px;
                        color: $body-color;
                        margin: 0 5px;
                    }
                }
                & a{
                    font-size: 15px;
                    color: $body-color;
                    transition: all 0.3s linear;
                    font-weight: 500;

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }
    & .blog-details-share{
        display: flex;
        margin-top: 15px;

        & .social{
            display: flex;
            margin-left: 10px;

            & a{
                width: 30px;
                height: 30px;
                line-height: 28px;
                border: 1px solid #a9adb0;
                border-radius: 50%;
                text-align: center;
                display: block;
                color: $dark;
                font-size: 15px;
                margin-right: 15px;

                &:last-child{
                    margin-right: 0;
                }

                &:hover{
                    border-color: $primary;
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}


// Blog Details Comments
.blog-details-comments{
    & .comments-wrpper{
       margin-top: 55px;
    }

    & .comment-title{
        font-size: 18px;
        font-weight: 700;
        color: $dark;
    }

    & .comments-items{
        & li{
            & + li{
                padding-top: 20px;
            }
        }
    }

    & .single-comment{
        display: flex;
        margin-top: 55px;

        & .comment-thumb{
            & img{
                border-radius: 50%;
            }
        }
        & .comment-content{
            flex: 1;
            padding-left: 20px;
            margin-top: -2px;

            & .comment-name-date{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 10px;

                & .name{
                    font-family: $font-family-base;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 0;

                    @media #{$small-mobile}{
                        font-size: 14px;
                    }

                    &::after{
                        content: '-';
                        margin: 0 8px;
                    }
                }
                & .date{
                    font-size: 15px;
                    font-weight: 500;

                    @media #{$small-mobile}{
                        font-size: 13px;
                    }
                }
            }
            & p{
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            & .reply{
                font-size: 15px;
                font-weight: 600;
                color: $primary;
            }
        }
    }

    & .comments-reply{
        padding-left: 90px;
        padding-top: 20px;

        @media #{$small-mobile}{
            padding-left: 0;
        }
    }

    & .comments-form{
        padding-top: 30px;
    }

    & .single-form{        
        & textarea{
            height: 220px;
            padding-top: 8px;
            resize: none;
        }
    }
}




