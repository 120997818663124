/*--
/*  5.1 - Footer Widget CSS
/*----------------------------------------*/

// Footer Widget Section 
.footer-widget-section{
    padding-top: 50px;
    padding-bottom: 100px;
    position: relative;

    @media #{$tablet-device} {
        padding-top: 30px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile} {
        padding-top: 10px;
        padding-bottom: 60px;
    }
}

// Footer Widget Title
.footer-widget-title{
    color: $dark;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

// Footer Widget 
.footer-widget{
    margin-top: 50px;

    & .widget-text{
        & a{}
        & p{
            margin-bottom: 0;
            color: #0b0b0b;
            margin-top: 22px;

            @media #{$desktop-device}{
                font-size: 15px;
            }
        }
    }
    & .widget-social{
        margin-top: 25px;

        & .title{
            font-size: 18px;
            display: inline-block;
            position: relative;
            padding-right: 5px;
            margin-bottom: 20px;
            color: #151616;

            &::before{
                position: absolute;
                content: '';
                width: 30px;
                height: 1px;
                background-color: #151616;
                top: 50%;
                transform: translateY(-50%);
                left: 100%;
            }
        }
        & .social{
            display: flex;

            & a{
                width: 30px;
                height: 30px;
                line-height: 28px;
                border: 1px solid #a9adb0;
                border-radius: 50%;
                text-align: center;
                display: block;
                color: $dark;
                font-size: 15px;
                margin-right: 15px;

                &:last-child{
                    margin-right: 0;
                }

                &:hover{
                    border-color: $primary;
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
    & .footer-widget-link{
        & ul{
            & + ul{
                padding-left: 30px;
            }
            & li{
                margin-top: 10px;

                & a{
                    color: #0b0b0b;
                    font-size: 16px;
                    transition: all 0.3s linear;
                    font-weight: 500;

                    @media #{$desktop-device}{
                        font-size: 15px;
                    }

                    & i{
                        margin-right: 5px;
                    }

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }
    & .widget-info-wrapper{
        padding-top: 12px;

        & .title{
            font-family: $font-family-base;
            font-size: 16px;
            color: $dark;
            font-weight: 600;
        }
        & .single-info{
            margin-top: 10px;

            & p{
                color: #0b0b0b;
                font-weight: 500;
                margin-bottom: 0;

                @media #{$desktop-device}{
                    font-size: 15px;
                }
            }
        }
    }
    &.widget-info{
        padding-left: 50px;

        @media #{$desktop-device}{
            padding-left: 0px;
        }
        @media #{$large-mobile}{
            padding-left: 0;
        }
    }
}

// Widget Link Wrapper
.widget-link-wrapper{
    display: flex;
    justify-content: space-around;

    @media #{$tablet-device}{
        justify-content: flex-start;
    }
    @media #{$large-mobile}{
        justify-content: flex-start;
    }
    @media #{$small-mobile}{
        display: block;
    }
    & .footer-widget{
        @media #{$tablet-device}{
            width: 50%;
        }

        & + .footer-widget{
            @media #{$large-mobile}{
                padding-left: 50px;
            }
            @media #{$small-mobile}{
                padding-left: 0px;
            }
        }
    }
}
