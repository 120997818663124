/*--
/*  4.8 - Testimonial CSS
/*----------------------------------------*/

// Testimonial Section
.testimonial-section{
    position: relative;
    padding-top: 40px;
    padding-bottom: 90px;

    & .shape{
        position: absolute;
        opacity: 0.3;

        &.shape-01{
            left: 30px;
            top: 65px;

            @media #{$large-mobile} {
                top: 0;
                left: 0;
                width: 65px;
            }
        }
        &.shape-02{
            bottom: 0;
            left: 18%;

            @media #{$large-mobile} {
                left: 8%;
                width: 100px;
            }
        }
        &.shape-03{
            top: 0;
            right: 30px;

            @media #{$large-mobile} {
                right: 0;
                width: 100px;
            }
        }
        &.shape-04{
            bottom: 60px;
            right: 18%;

            @media #{$large-mobile} {
                right: 8px;
                width: 65px;
            }
        }
    }
}

// Testimonial Wrapper
.testimonial-wrapper{
    
}

// Single Testimonial
.single-testimonial{
    & img{}
    & p{
        color: #0b0b0b;
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 20px;
        line-height: 1.75;

        & br{
            @media #{$large-mobile} {
                display: none;
            }
        }
    }
    & .author-name{
        font-size: 18px;
        color: $dark;
        margin-bottom: 3px;

        @media #{$large-mobile} {
            font-size: 16px;
        }
    }
    & .designation{
        color: #525252;
        font-family: $headings-font-family;
        font-size: 15px;

        @media #{$large-mobile} {
            font-size: 14px;
        }
    }
}


.testimonial-active{
    text-align: center;
    & .testimonial-arrow{
        padding-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .swiper-button-next,
    & .swiper-button-prev{
        transition: all 0.3s linear;
        position: relative;
        width: auto;
        height: auto;
        top: 0;
        margin: 0 3px;

        &::after{
            display: none;
        }
        & span{
            font-size: 16px;
            line-height: 24px;
            color: #aaaaaa;
            position: relative;
            transition: all 0.3s linear;
        }
        &:hover{
            & span{
                color: $dark;
            }
        }
    }
    & .swiper-button-next{
        right: 0;
    }
    & .swiper-button-prev{
        left: 0;

        & span{
            transform: rotateY(180deg);
        }
    }   
}









