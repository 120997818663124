/*--
/*  4.15 - Login & Register CSS
/*----------------------------------------*/


.login-register-wrapper{
    & .title{
        font-size: 24px;
        font-weight: 500;
        font-family: $font-family-base;
        color: $dark;

        @media #{$small-mobile}{
            font-size: 18px;
        }
    }
    
    & .single-form{
        & .btn{
            width: 160px;
            margin-bottom: 20px;
        }
    }
     
    & p{
        font-size: 15px;
    }
}









