/*--
/*  4.7 - About CSS
/*----------------------------------------*/

// About Section 
.about-section{
    position: relative;
    margin-top: -130px;

    @media #{$large-mobile}{
        margin-top: -50px;
    }
}

// About Content 
.about-content{
    & .about-title{
        font-size: 38px;
        margin-top: 60px;
        margin-bottom: 30px;
        line-height: 1.5;
        color: $dark;

        @media #{$large-mobile} {
            font-size: 20px;
        }
        & br{
            @media #{$large-mobile} {
                display: none;
            }
        }
    }
    & p{
        color: #0b0b0b;
        font-size: 16px;
        line-height: 1.75;
    }
    & ul{
        padding-top: 2px;
        & li{
            color: #0b0b0b;
            font-size: 15px;
            margin-top: 14px;
            position: relative;
            padding-left: 12px;

            &::before{
                content: '-';
                position: absolute;
                top: 0px;
                left: 0;
            }
        }
    }

    &.about-item-01{
        padding-right: 30px;
        padding-top: 35px;

        @media #{$large-mobile} {
            padding-right: 0;
        }
        & .title{
            font-size: 28px;
            margin-bottom: 15px;
            color: $dark;
            
            @media #{$large-mobile} {
                font-size: 20px;
            }
        }
        & ul{
            width: 60%;
            display: flex;
            flex-wrap: wrap;

            @media #{$large-mobile} {
                width: 100%;
            }

            & li{
                display: inline-block;
                width: 50%;    
                
                @media #{$small-mobile}{
                    width: 100%;
                }
            }
        }
    }

    &.about-item-02{
        padding-left: 30px;
        padding-top: 35px;

        @media #{$large-mobile} {
            padding-left: 0;
        }

        & .title{
            font-size: 28px;
            margin-bottom: 15px;
            color: $dark;

            @media #{$large-mobile} {
                font-size: 20px;
            }
        }
        & ul{
            & li{}
        }
    }
}

// About Wrapper 
.about-wrapper{
    padding-top: 10px;
}

// About Image 
.about-image-01{
    padding-left: 30px;
    padding-top: 40px;
    @media #{$large-mobile} {
        padding-left: 0;
    }
}
.about-image-02{
    padding-right: 30px;
    padding-top: 40px;
    @media #{$large-mobile} {
        padding-right: 0;
    }
}




