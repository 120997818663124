/*--
/*  4.4 - Blog CSS
/*----------------------------------------*/

// Blog Wrapper 
.blog-wrapper{
    padding-top: 20px;
}


// Single Blog 
.single-blog{
    background-color: #eaf1f9;
    margin-top: 30px;

    & .blog-image{
        & a{
            overflow: hidden;
            display: block;
        }
        & img{
            transition: all 0.3s linear;
            width: 100%;
        }
    }
    & .blog-content{
        padding: 25px;
        
        @media #{$large-mobile}{
            padding: 20px;
        }

        & .blog-meta{
            display: flex;

            & > * {
                font-size: 15px;
                font-weight: 500;
                color: $primary;

                & + * {
                    &::before{
                        content: '/';
                        margin: 0 8px;
                    }
                }
            }
        }
        & .title{
            & a{
                font-size: 18px;
                font-weight: 700;
                color: $dark;

                @media #{$desktop-device}{
                    font-size: 16px;
                }
                @media #{$tablet-device}{
                    font-size: 16px;
                }
                @media #{$large-mobile}{
                    font-size: 15px;
                }

                &:hover{
                    color: $primary;
                }

                & br{
                    @media #{$desktop-device}{
                        display: none;
                    }
                    @media #{$tablet-device, $large-mobile}{
                        display: none;
                    }
                }
            }
        }
        & .more{
            color: #757575;
            font-size: 15px;
            font-weight: 500;
            transition: all 0.3s linear;

            &:hover{
                color: $primary;
            }
        }
    }
    &:hover{
        & .blog-image{
            & img{
                transform: scale(1.1);
            }
        }
    }
}












